.entry-wrapper {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 26px;
    font-weight: 100;
    color: white;
}

a {
    color: #f6c992;
  }
  
  a:hover {
    color:  #689595;
  }

.bio {
    text-align: left
}

.name {
    font-family: "crates";
    font-size: 52px
}

.title {
    color: white;
    font-weight: 50;
    font-size: 26px
}

.bio-text {
    text-align: justify
}

.amanda {
    font-size: 24px
}

img.portrait{
    height:50vh;

}

@media only screen and (max-width: 1100px) {
    img.portrait {
      height: 20vh;
    }
    .portrait {
        margin: 0;
        padding: 0
    }
    .name {
        font-size: 32px
    }
    .title {
        font-size: 20px
    }
    .bio-text {
        font-size: 20px;
    }
    .amanda {
        font-size: 18px
    }
    body {
        line-height: 1;
    }
  }

  @media only screen and (max-width: 325px) {
    .name {
        font-size: 26px
    }
    .title {
        font-size: 20px
    }
    .bio-text {
        font-size: 18px;
    }
    .amanda {
        font-size: 16px
    }
  }