img.def-button{
    height:8vh;
    margin: 5px;
    border-radius: 50%;
    box-shadow: .5px .5px 2px 2px #689595;
}

img.def-button:hover{
    box-shadow: 1px 1px 2px 2px white
}

img.selected-button{
    height:8vh;
    margin: 5px;
    border-radius: 50%;
    box-shadow: .5px .5px 2px 2px #f6c992;
}

@media only screen and (max-width: 600px) {
    img.def-button {
      height: 7vh;
    }
    img.selected-button {
      height: 7vh
    }
  }