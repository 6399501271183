.VG04-white {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 5vw black solid;
    color: black;
    font-family: "Times New Roman";
    height: 100vh;
    font-size: 20px;
    padding: 15px;
}

.VG04-white-in {
    font-size: 20px;
    padding: 15px;
    -webkit-animation: text-focus-in 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: text-focus-in 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }

.VG04-white-out {
  padding: 15px;
}

.VG04-error {
  -webkit-animation: text-flicker-out-glow 2.5s linear 0s both;
  animation: text-flicker-out-glow 2.5s linear 0s both;
}

.VG04-black {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: black;
  font-family: "Times New Roman";
  height: 100vh;
}

.VG04-black-in {
  font-size: 20px;
  padding: 15px;
  -webkit-animation: text-focus-in 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: text-focus-in 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

.VG04-black-out {    
font-size: 20px;
}

.VG04-grey {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  font-family: "Times New Roman";
  height: 100vh;
}

.VG04-grey-in {
  font-size: 20px;
  padding: 5px;
  }

.VG04-grey-out {    
font-size: 20px;
padding: 5px;
}


.VG04-egg {
  -webkit-animation: wobble-hor-bottom 2s linear infinite both;
	        animation: wobble-hor-bottom 2s linear infinite both;
}

.chaos-egg-1 {
  animation: chaos-egg-1 4s linear infinite both;
}

.chaos-egg-2 {
  animation: chaos-egg-1 2s linear infinite both;
}

.chaos-egg-3 {
  animation: chaos-egg-1 1s linear infinite both;
}

.chaos-egg-4 {
  animation: chaos-egg-1 .5s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-18 18:10:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}

@keyframes chaos-egg-1 {
  0% {
    border-right: 0px;
  }
  10%{
    border-left: 10px solid 
  }
  30% {
    border-top: 0px;
  } 
  40% {
    border-bottom: 25px solid;
    position:relative;
    top: 15%;
    left: 50px;
  }
  50% {
    transform: translate(-2px, 2px)
  }
  60% {
    transform: translate(-2px, -2px)
  }
  67% {
    transform: translate(2px, -2px)
  }
  75%{
    transform: translate(2px, 2px);
    position: relative;
    bottom: 2px;
    right: 100px
  }
  87%{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transform: translate(-2px, 2px)
  }
  94%{
    font-family: 'Times New Roman', Times, serif;
    transform: translate(-2px, -2px)
  }
  100%{
    transform: translate(2px, -2px)
  }

}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-18 17:59:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-flicker-out-glow
 * ----------------------------------------
 */
 @-webkit-keyframes text-flicker-out-glow {
  0% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 0;
    text-shadow: none;
  }
  14.9% {
    opacity: 0;
    text-shadow: none;
  }
  15% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 0;
    text-shadow: none;
  }
  24.9% {
    opacity: 0;
    text-shadow: none;
  }
  25% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 0;
    text-shadow: none;
  }
  39.9% {
    opacity: 0;
    text-shadow: none;
  }
  40% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 0;
    text-shadow: none;
  }
  44.9% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 0;
    text-shadow: none;
  }
  69.4% {
    opacity: 0;
    text-shadow: none;
  }
  69.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 0;
    text-shadow: none;
  }
  79.4% {
    opacity: 0;
    text-shadow: none;
  }
  79.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 0;
    text-shadow: none;
  }
  89.8% {
    opacity: 0;
    text-shadow: none;
  }
  89.9% {
    opacity: 1;
    text-shadow: none;
  }
  90% {
    opacity: 0;
    text-shadow: none;
  }
  100% {
    opacity: 0;
  }
}
@keyframes text-flicker-out-glow {
  0% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 0;
    text-shadow: none;
  }
  14.9% {
    opacity: 0;
    text-shadow: none;
  }
  15% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 0;
    text-shadow: none;
  }
  24.9% {
    opacity: 0;
    text-shadow: none;
  }
  25% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 0;
    text-shadow: none;
  }
  39.9% {
    opacity: 0;
    text-shadow: none;
  }
  40% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 0;
    text-shadow: none;
  }
  44.9% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 0;
    text-shadow: none;
  }
  69.4% {
    opacity: 0;
    text-shadow: none;
  }
  69.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 0;
    text-shadow: none;
  }
  79.4% {
    opacity: 0;
    text-shadow: none;
  }
  79.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 0;
    text-shadow: none;
  }
  89.8% {
    opacity: 0;
    text-shadow: none;
  }
  89.9% {
    opacity: 1;
    text-shadow: none;
  }
  90% {
    opacity: 0;
    text-shadow: none;
  }
  100% {
    opacity: 0;
  }
}
