.beep-eyes {
    font-size: 80px;
    position: relative;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(157, 243, 214);
    padding: 5px;
    margin: 0px;
    height: 120px;
    text-shadow: 0px 0px 28px rgb(88, 193, 228);
}

.bot-text{
    text-align: justify;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 1000;
    font-size: 32px;
    text-shadow: 0px 0px 28px rgb(88, 193, 228);
    
  }

.dia-text{
    font-family: 'bubble';
    font-size: 32px;
    text-align: justify;
}

  

.vibrate-2 {
	-webkit-animation: vibrate-2 4s linear infinite both;
	        animation: vibrate-2 4s linear infinite both;	
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-16 7:12:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-2
 * ----------------------------------------
 */
 @-webkit-keyframes vibrate-2 {
    0% {
      -webkit-transform: translate(0) rotate(0.25turn);
              transform: translate(0) rotate(0.25turn);
    }
    20% {
      -webkit-transform: translate(4px, -4px) rotate(0.23turn);
              transform: translate(4px, -4px) rotate(0.23turn);
    }
    40% {
      -webkit-transform: translate(4px, 4px) rotate(0.25turn);
              transform: translate(4px, 4px) rotate(0.25turn);
    }
    60% {
      -webkit-transform: translate(-4px, 4px) rotate(0.26turn);
              transform: translate(-4px, 4px) rotate(0.26turn);
    }
    80% {
      -webkit-transform: translate(-4px, -4px) rotate(0.25turn);
              transform: translate(-4px, -4px) rotate(0.25turn);
    }
    100% {
      -webkit-transform: translate(0) rotate(0.25turn);
              transform: translate(0) rotate(0.25turn);
    }
  }
  @keyframes vibrate-2 {
    0% {
      -webkit-transform: translate(0) rotate(0.25turn);
              transform: translate(0) rotate(0.25turn);
    }
    20% {
      -webkit-transform: translate(4px, -4px) rotate(0.26turn);
              transform: translate(4px, -4px) rotate(0.26turn);
    }
    40% {
      -webkit-transform: translate(5px, 6px) rotate(0.25turn);
              transform: translate(5px, 6px) rotate(0.25turn);
    }
    60% {
      -webkit-transform: translate(-4px, 4px) rotate(0.22turn);
              transform: translate(-4px, 4px) rotate(0.22turn);
    }
    80% {
      -webkit-transform: translate(-4px, -4px) rotate(0.25turn);
              transform: translate(-4px, -4px) rotate(0.25turn);
    }
    100% {
      -webkit-transform: translate(0) rotate(0.25turn);
              transform: translate(0) rotate(0.25turn); 
    }
  }
