@font-face {
  font-family: 'crates';
  font-style: normal;
  font-weight: normal;
  src: local('crates'), url(./fonts/crates.ttf) format('truetype')
}

@font-face {
  font-family: 'roundabout';
  font-style: normal;
  font-weight: normal;
  src: local('roundabout'), url(./fonts/roundabout.ttf) format('truetype')
}

@font-face {
  font-family: 'lovebug';
  font-style: normal;
  font-weight: normal;
  src: local('lovebug'), url(./fonts/lovebug.ttf) format('truetype')
}

@font-face {
  font-family: 'little';
  font-style: normal;
  font-weight: normal;
  src: local('little'), url(./fonts/little.ttf) format('truetype')
}

@font-face {
  font-family: 'bubble';
  font-style: normal;
  font-weight: normal;
  src: local('bubble'), url(./fonts/bubble.ttf) format('truetype')
}

@font-face {
  font-family: 'oeuf';
  font-style: normal;
  font-weight: normal;
  src: local('oeuf'), url(./fonts/oeuf.ttf) format('truetype')
}

body {
  margin: 0;
  font-family: 'roundabout';
  font-smooth: never;
  -webkit-font-smoothing: none;
}

html::-webkit-scrollbar{
  display: none
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
