#deltarune-text{
	-webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1s both;
	        animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1s both;
}

#deltarune-box{
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.menu-App {
  background-color: black;
  text-align: center;
  min-height: 100vh
}

.menu-logo {
  height: 20vmin;
  pointer-events: none;
}

.menu-title {
  color:white;
  font: roundabout;
  font-size: 96px;
  font-weight: 50;
  animation-name: menu-appear;
  animation-duration: 1.5s
}

.menu-poems-button {
  color: #f6c992;
  font-size: 32px;
  font-weight: 50;
  animation-name: menu-appearPoems;
  animation-duration: 2s;
  margin: 5px;
  padding-left: 8px;
  padding-right: 6px;
  padding-bottom: 5px;
  max-height: 40px;
  
}

.menu-poems-button:hover {
  color: #D55C5A
}

.menu-options {
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%
}

.menu-option {  
  animation-name: menu-appearLink;  
  font-weight: 50;
  font-size: 32px;
  color: #f6c992
}

.menu-option:hover {
  color: #ba767e
}

.menu-option:active {
  color: #f2cbc9
}

.menu-op1 {
  animation-duration: 3s;
}

.menu-op2 {
  animation-duration: 3.5s;
}

.menu-op3 {
  animation-duration: 4s;
}

.menu-op4 {
  animation-duration: 4.5s;
}

.menu-logo-wrapper {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%
}

@media (prefers-reduced-motion: no-preference) {
  .menu-logo {
    animation: App-logo-spin infinite 8s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes menu-appear {
  from {
    color: black
  } to {
    color: white
  }
}

@keyframes menu-appearLink {
  0%{color:black}
  50%{color:black}
  100%{color:#f6c992}
}

@keyframes menu-appearPoems {
  from {
    margin-left:300%;
    width: 300%
  }
  to {
    margin-left: 0%;
    width: 100%
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}


@media only screen and (max-width: 600px) {
  .menu-title {
    font-size: 64px
  }
  .menu-option {
    font-size: 26px
  }
}
