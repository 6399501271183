.App {
  background-color: black;
  text-align: center;
  min-height: 100vh
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 15s linear;
  }
}

.App-header {
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 100;
  font-size: 26px;
  padding-left: 10%;
  padding-right: 10%
}

.buffer {
  height: 20vh
}

@keyframes App-logo-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 600px) {
  .App-logo {
    height: 70vmin
  }
}
