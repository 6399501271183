.background-jm {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d0a2c;
  background-image:
    radial-gradient(#0a0124 20%, transparent 0),
    radial-gradient(#0d003b 20%, transparent 0);
  background-size: 100px 100px;
  background-position: 0 0, 50px 50px;
  height: 100vh
}

.JM05-day {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    border: 10px rgb(160, 0, 0) solid;
    box-shadow: 5px 5px rgb(66, 31, 31);
    border-radius: 10px;
    color: black;
    font-family: "lovebug";
    height: 568px;
    width: 320px;
    position: relative;
    -webkit-animation: scale-in-ver-bottom 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
    animation: scale-in-ver-bottom 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
}

.JM05-top {
  display: inline-block;
  min-height: 35px;
  background-color: rgb(160, 0, 0);
  position: absolute;
  top: 0px;
  width: 305px
}

.JM05-bottom {
  display: inline-block;
  min-height: 35px;
  background-color: rgb(160, 0, 0);
  position: absolute;
  bottom: 0px;
  width: 305px
}

.message-field-jm {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 320px;
  width: 290px;
  position: absolute;
  top: 85px;
  left: 5px;
  line-height: 1.2;
  font-size: 16px;
  overflow: scroll
}

.indv-message-sent {
  margin-left: 25px;
  padding: 8px;
  background-color: rgb(63, 62, 62);
  border-radius: 5px;
  color: white;
  margin-bottom: 10px;
}

.time-stamp {
  font-size: 12px;
  position: relative;
  left: 25px;
  margin: 5px;
  color: rgb(63, 62, 62)
}

.contact-name-jm {
text-align: center;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-weight: 800;
font-size: 25px;
position: absolute;
top: 40px;
border-bottom: 2px black solid
}

.message-box-jm {
  position: absolute;
  bottom: 75px;
  left: 5px
}

#text-box-jm {
  font-size: 16px;
  resize: none;
  width: 290px;
  border: 0;
  border-radius: 5px;
  padding: 5px;
}

#text-box-jm:focus-visible{
  outline: 0 white solid;
  outline-style: inherit;
}

#send-button-jm {
  border: 0;
  padding: 0 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  background-color: #23B5D3;
  height: 32px;
  width: 200px;
  position: absolute;
  bottom: -35px;
  left: 45px
}

#send-button-jm:hover {
  background-color: #1989a0;
}

#send-button-jm:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
              inset 2px 2px 3px rgba(0, 0, 0, .6);
}

#send-button-jm:disabled {
  background-color: grey;
  color: rgb(102, 102, 102)
}
/* ----------------------------------------------
 * Generated by Animista on 2021-6-16 15:49:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
