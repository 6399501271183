.MB03-white {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: peachpuff;
    border: 20px palevioletred solid;
    color: black;
    font-family: "lovebug";
    height: 100vh;
}

.MB03-white-in {
    font-size: 24px;
    border: 8px solid black;
    border-radius: 10px;
    background-color:paleturquoise;
    padding: 15px;
    	-webkit-animation: rotate-in-diag-2 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: rotate-in-diag-2 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

.MB03-white-out {    
  font-size: 24px;
  border: 8px solid black;
  border-radius: 10px;
  background-color:paleturquoise;
  padding: 15px;
  -webkit-animation: rotate-out-diag-2 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: rotate-out-diag-2 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

}

.MB03-black {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: rgb(161, 121, 18);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 900;
  height: 100vh;
  -webkit-animation: color-change-5x 8s linear infinite alternate both;
  animation: color-change-5x 8s linear infinite alternate both;
}

.MB03-black-in {
  font-size: 24px;
	-webkit-animation: text-focus-in 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

.MB03-black-out {    
font-size: 24px;
-webkit-animation: blur-out-expand 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
animation: blur-out-expand 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.paper-person {
  font-family: "little";
  color: silver;
	-webkit-animation: text-pop-up-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.5s both;
	        animation: text-pop-up-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.5s both;
}

.MB03-blue {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(176, 241, 176);
  color: black;
  font-family: "crates";
  font-weight: 200;
  height: 100vh; 
}

.MB03-blue-in {
  border: 8px solid rgb(18, 126, 153);
  border-radius: 10px;
  background-color:snow;
  padding: 15px;
  font-size: 24px;
	-webkit-animation: swing-in-top-bck 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) 0.3s both;
	        animation: swing-in-top-bck 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) 0.3s both;  
}
.MB03-blue-out {    
  border: 8px solid rgb(18, 126, 153);
  border-radius: 10px;
  background-color:snow;
  padding: 15px;
  font-size: 24px;
	-webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2021-2-17 16:9:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-flicker-in-glow
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}


  @-webkit-keyframes text-flicker-out-glow {
    0% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    13.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    14% {
      opacity: 0;
      text-shadow: none;
    }
    14.9% {
      opacity: 0;
      text-shadow: none;
    }
    15% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    22.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    23% {
      opacity: 0;
      text-shadow: none;
    }
    24.9% {
      opacity: 0;
      text-shadow: none;
    }
    25% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    34.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    35% {
      opacity: 0;
      text-shadow: none;
    }
    39.9% {
      opacity: 0;
      text-shadow: none;
    }
    40% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
    }
    42.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
    }
    43% {
      opacity: 0;
      text-shadow: none;
    }
    44.9% {
      opacity: 0;
      text-shadow: none;
    }
    45% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    50% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    54.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    55% {
      opacity: 0;
      text-shadow: none;
    }
    69.4% {
      opacity: 0;
      text-shadow: none;
    }
    69.5% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    69.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    70% {
      opacity: 0;
      text-shadow: none;
    }
    79.4% {
      opacity: 0;
      text-shadow: none;
    }
    79.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
    }
    80% {
      opacity: 0;
      text-shadow: none;
    }
    89.8% {
      opacity: 0;
      text-shadow: none;
    }
    89.9% {
      opacity: 1;
      text-shadow: none;
    }
    90% {
      opacity: 0;
      text-shadow: none;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes text-flicker-out-glow {
    0% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    13.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    14% {
      opacity: 0;
      text-shadow: none;
    }
    14.9% {
      opacity: 0;
      text-shadow: none;
    }
    15% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    22.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    23% {
      opacity: 0;
      text-shadow: none;
    }
    24.9% {
      opacity: 0;
      text-shadow: none;
    }
    25% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    34.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
    }
    35% {
      opacity: 0;
      text-shadow: none;
    }
    39.9% {
      opacity: 0;
      text-shadow: none;
    }
    40% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
    }
    42.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
    }
    43% {
      opacity: 0;
      text-shadow: none;
    }
    44.9% {
      opacity: 0;
      text-shadow: none;
    }
    45% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    50% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    54.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    55% {
      opacity: 0;
      text-shadow: none;
    }
    69.4% {
      opacity: 0;
      text-shadow: none;
    }
    69.5% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    69.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
    }
    70% {
      opacity: 0;
      text-shadow: none;
    }
    79.4% {
      opacity: 0;
      text-shadow: none;
    }
    79.9% {
      opacity: 1;
      text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
    }
    80% {
      opacity: 0;
      text-shadow: none;
    }
    89.8% {
      opacity: 0;
      text-shadow: none;
    }
    89.9% {
      opacity: 1;
      text-shadow: none;
    }
    90% {
      opacity: 0;
      text-shadow: none;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes blur-out-expand {
    0% {
      -webkit-filter: blur(0.01);
              filter: blur(0.01);
    }
    100% {
      letter-spacing: 1em;
      -webkit-filter: blur(12px) opacity(0%);
              filter: blur(12px) opacity(0%);
    }
  }
  @keyframes blur-out-expand {
    0% {
      -webkit-filter: blur(0.01);
              filter: blur(0.01);
    }
    100% {
      letter-spacing: 1em;
      -webkit-filter: blur(12px) opacity(0%);
              filter: blur(12px) opacity(0%);
    }
  }

  @-webkit-keyframes text-pop-up-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      text-shadow: none;
    }
    100% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      text-shadow: 0 1px 0 rgb(41, 0, 0), 0 2px 0 rgb(41, 0, 0), 0 3px 0 rgb(41, 0, 0), 0 4px 0 rgb(41, 0, 0), 0 5px 0 rgb(41, 0, 0), 0 6px 0 rgb(41, 0, 0), 0 7px 0 rgb(41, 0, 0), 0 8px 0 rgb(41, 0, 0), 0 9px 0 rgb(41, 0, 0), 0 50px 30px rgba(0, 0, 0, 0.3);
    }
  }
  @keyframes text-pop-up-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      text-shadow: none;
    }
    100% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      text-shadow: 0 1px 0 rgb(41, 0, 0), 0 2px 0 rgb(41, 0, 0), 0 3px 0 rgb(41, 0, 0), 0 4px 0 rgb(41, 0, 0), 0 5px 0 rgb(41, 0, 0), 0 6px 0 rgb(41, 0, 0), 0 7px 0 rgb(41, 0, 0), 0 8px 0 rgb(41, 0, 0), 0 9px 0 rgb(41, 0, 0), 0 50px 30px rgba(0, 0, 0, 0.3);
    }
  }
  
  @-webkit-keyframes swing-in-top-bck {
    0% {
      -webkit-transform: rotateX(70deg);
              transform: rotateX(70deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-bck {
    0% {
      -webkit-transform: rotateX(70deg);
              transform: rotateX(70deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }

  @-webkit-keyframes roll-out-blurred-left {
    0% {
      -webkit-transform: translateX(0) rotate(0deg);
              transform: translateX(0) rotate(0deg);
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px) rotate(-720deg);
              transform: translateX(-1000px) rotate(-720deg);
      -webkit-filter: blur(50px);
              filter: blur(50px);
      opacity: 0;
    }
  }
  @keyframes roll-out-blurred-left {
    0% {
      -webkit-transform: translateX(0) rotate(0deg);
              transform: translateX(0) rotate(0deg);
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px) rotate(-720deg);
              transform: translateX(-1000px) rotate(-720deg);
      -webkit-filter: blur(50px);
              filter: blur(50px);
      opacity: 0;
    }
  }

  @-webkit-keyframes color-change-5x {
    0% {
      background: #000000;
    }
    25% {
      background: #0f0f25;
    }
    50% {
      background: #090844;
    }
    65% {
      background: #0f0c03;
    }
    100% {
      background: #440202;
    }
  }
  @keyframes color-change-5x {
    0% {
      background: #000000;
    }
    25% {
      background: #0f0f25;
    }
    50% {
      background: #080944;
    }
    65% {
      background: #0f0c03;
    }
    100% {
      background: #440202;
    }
  }
  
  @-webkit-keyframes rotate-in-diag-2 {
    0% {
      -webkit-transform: rotate3d(-1, 1, 0, -360deg);
              transform: rotate3d(-1, 1, 0, -360deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate3d(-1, 1, 0, 0deg);
              transform: rotate3d(-1, 1, 0, 0deg);
      opacity: 1;
    }
  }
  @keyframes rotate-in-diag-2 {
    0% {
      -webkit-transform: rotate3d(-1, 1, 0, -360deg);
              transform: rotate3d(-1, 1, 0, -360deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate3d(-1, 1, 0, 0deg);
              transform: rotate3d(-1, 1, 0, 0deg);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes rotate-out-diag-2 {
    0% {
      -webkit-transform: rotate3d(-1, 1, 0, 360deg);
              transform: rotate3d(-1, 1, 0, 360deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate3d(-1, 1, 0, 0deg);
              transform: rotate3d(-1, 1, 0, 0deg);
      opacity: 0;
    }
  }
  @keyframes rotate-out-diag-2 {
    0% {
      -webkit-transform: rotate3d(-1, 1, 0, 360deg);
              transform: rotate3d(-1, 1, 0, 360deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate3d(-1, 1, 0, 0deg);
              transform: rotate3d(-1, 1, 0, 0deg);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  