.jn02 {
    min-height: 100vh;
    max-height: max-content
}
.jn02-title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 24px;
}
.jn02-sub {
    font-size: 16px
}
.jn02-poem-box {
    padding-top: 10vh;
    margin-left: 10vw;
    margin-right: 10vw;
    min-height: 80vh;
    color: black;
    font-family: 'Times New Roman', Times, serif;
}
.jn02-poem-box-square {
    height: 300px;
    margin-right: 5vw;
    padding: 10%;
}
.jn02-box {
    width: 200px;
    height: 200px;
    padding: 10px;
    border: 1px solid black;
    font-size: 48px;
}
.jn02-number {
    font-size: 48px;
}
.jn02-12px {
    font-size: 14px
}

.jn02-sideways-right {
    transform: rotate(90deg);
    position: relative;
    padding-bottom: 200px;
    padding-right: 50px;
}

.jn02-sideways-left {
    transform: rotate(270deg);
    position: relative;
    right: 100px;
}

.jn02-sideways-down {
    transform: rotate(180deg);
    position: relative;
    top: 80px
}

.jn02-poem-box-content {
    padding: 0%;
    font-size: 20px;
}
.jn02-red {
    color: red
}

.jn02-options {
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .jn02-col {
    overflow: hidden;
    white-space: nowrap;
    padding-left: 20px;
    padding-right: 20px;
  }
  
.jn02-option {  
    font-weight: 50;
    font-size: 20px;
    color: black;
  }

  .jn02-option:hover {
      color: maroon
  }

  .vibrate-1 {
	-webkit-animation: vibrate-1 1s linear infinite both;
	        animation: vibrate-1 1s linear infinite both;
}

.day-7-box {
    height: 160px;
    width: 160px;
    background-color: white;
    border-right: 40px solid white;
    border-left: 40px solid white;
    border-bottom: 20px solid white;
}

.orange {
    background-color: orange;
}

.red{
    background-color: red;
}

.pink {
    background-color: magenta;
}
.light-blue{
    background-color: lightblue;
}
.light-green{
    background-color: lightgreen
}
.black {
    background-color: black
}
.light-gray {
    background-color: lightgrey
}

.no-margin{
    margin: 0;
    padding: 0
}

.center {
    text-align: center;
}

/* day 2 */

  .day2 {
      font-size: 16px;
      text-align: justify;

  }

  .jn02-box-2 {
    margin-left: 70px;
    width: 200px;
    height: 200px;
    padding: 10px;
    border: 1px solid black;
    font-size: 48px;
}

.ten-box {
  animation: ten-box 4s infinite both;
}
/* animations */

.shadow-pop-tr {
	-webkit-animation: shadow-pop-tr 5s steps(6, end) infinite alternate both;
	        animation: shadow-pop-tr 5s steps(6, end) infinite alternate both;
}
.jello-diagonal-2 {
	-webkit-animation: jello-diagonal-2 4s infinite both;
	        animation: jello-diagonal-2 4s infinite both;
}

.flicker-4 {
	-webkit-animation: flicker-4 8s linear infinite both;
	        animation: flicker-4 8s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-17 15:18:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-pop-tr
 * ----------------------------------------
 */
 @-webkit-keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}
@keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}

@keyframes ten-box {
  0% {
    border-right: 0px;
  }
  10%{
    border-left: 10px solid 
  }
  30% {
    border-top: 0px;
  } 
  40% {
    border-bottom: 25px solid;
    position:relative;
    top: 15%;
    left: 50px;
  }
  50% {
    transform: translate(-2px, 2px)
  }
  60% {
    transform: translate(-2px, -2px)
  }
  67% {
    transform: translate(2px, -2px)
  }
  75%{
    transform: translate(2px, 2px);
    position: relative;
    bottom: 2px;
    right: 100px
  }
  87%{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transform: translate(-2px, 2px)
  }
  94%{
    font-family: 'Times New Roman', Times, serif;
    transform: translate(-2px, -2px)
  }
  100%{
    transform: translate(2px, -2px)
  }

}

 @-webkit-keyframes shadow-pop-tr {
    0% {
      -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
              box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
      -webkit-transform: translateX(0) translateY(0);
              transform: translateX(0) translateY(0);
    }
    100% {
      -webkit-box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
              box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
      -webkit-transform: translateX(-8px) translateY(8px);
              transform: translateX(-8px) translateY(8px);
    }
  }
  @keyframes shadow-pop-tr {
    0% {
      -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
              box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
      -webkit-transform: translateX(0) translateY(0);
              transform: translateX(0) translateY(0);
    }
    100% {
      -webkit-box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
              box-shadow: 1px -1px #3e3e3e, 2px -2px #3e3e3e, 3px -3px #3e3e3e, 4px -4px #3e3e3e, 5px -5px #3e3e3e, 6px -6px #3e3e3e, 7px -7px #3e3e3e, 8px -8px #3e3e3e;
      -webkit-transform: translateX(-8px) translateY(8px);
              transform: translateX(-8px) translateY(8px);
    }
  }
  
  @-webkit-keyframes vibrate-1 {
    0% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  @keyframes vibrate-1 {
    0% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  
  @-webkit-keyframes jello-diagonal-2 {
    0% {
      -webkit-transform: skew(0deg 0deg);
              transform: skew(0deg 0deg);
    }
    30% {
      -webkit-transform: skew(-25deg -25deg);
              transform: skew(-25deg -25deg);
    }
    40% {
      -webkit-transform: skew(15deg, 15deg);
              transform: skew(15deg, 15deg);
    }
    50% {
      -webkit-transform: skew(-15deg, -15deg);
              transform: skew(-15deg, -15deg);
    }
    65% {
      -webkit-transform: skew(5deg, 5deg);
              transform: skew(5deg, 5deg);
    }
    75% {
      -webkit-transform: skew(-5deg, -5deg);
              transform: skew(-5deg, -5deg);
    }
    100% {
      -webkit-transform: skew(0deg 0deg);
              transform: skew(0deg 0deg);
    }
  }
  @keyframes jello-diagonal-2 {
    0% {
      -webkit-transform: skew(0deg 0deg);
              transform: skew(0deg 0deg);
    }
    30% {
      -webkit-transform: skew(-25deg -25deg);
              transform: skew(-25deg -25deg);
    }
    40% {
      -webkit-transform: skew(15deg, 15deg);
              transform: skew(15deg, 15deg);
    }
    50% {
      -webkit-transform: skew(-15deg, -15deg);
              transform: skew(-15deg, -15deg);
    }
    65% {
      -webkit-transform: skew(5deg, 5deg);
              transform: skew(5deg, 5deg);
    }
    75% {
      -webkit-transform: skew(-5deg, -5deg);
              transform: skew(-5deg, -5deg);
    }
    100% {
      -webkit-transform: skew(0deg 0deg);
              transform: skew(0deg 0deg);
    }
  }
  

  @media only screen and (max-width: 700px) {
      .day-7-box {
          height: 90px;
          width: 90px;
          border: 0px;
          margin: 2px;
      }
      .jn02-poem-box-content {
          font-size: 14px;
      }
      .jn02-poem-box {
        padding-top: 10px;
        margin-left: 0px;
        margin-right: 10px;
      }
      .jn02-box {
        height: 100px;
        width: 100px;
        padding: 5px;
        font-size: 24px;
    }
    .jn02-number {
        font-size: 24px;
    }
    .jn02-poem-box-square {
        height: 120px;
        padding: 10%;
    }
    .jn02-sideways-left{
        top: 25px;
    }
    .jn02-sideways-down{
        top: 115px;
    }
    /* day 2 */
    .day2 {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1.2rem
  }

}