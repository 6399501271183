   .poem-App {
    background-color:black;
    text-align: center;
    min-height: 100vh
  }
  
  .poem-title {
    color:#f6c992;
    font: roundabout;
    font-size: 72px;
    font-weight: 50;
    animation-name: abt-appear;
    animation-duration: 2s
  }
  
  .poem-options {
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%
  }
  
.poem-preview-box {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%
}

  .poem-option {  
    animation-name: abt-appearLink;  
    font-weight: 50;
    font-size: 32px;
    color: #689595;
  }
  
  .poem-option:hover {
    color: #9c6853
  }
  
  .poem-option:active {
    color: #fbdfcb
  }
  
  .poem-op1 {
    animation-duration: 3s;
  }
  .poem-op2 {
    animation-duration: 1s;
  }
  .poem-op3 {
    animation-duration: 1.5s;
  }
  
  .poem-desc-wrapper {
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20%;
    padding-right: 20%; 
  }
  
  .poem-desc {
    text-align: justify;
    animation-name: abt-appearDesc; 
  }

  .poem-preview {
    background-color:rgb(16, 16, 59);
    border: 2px solid black;
    border-radius: 15px;
    padding: 25px;
    color: white;
    animation-name: menu-appearPoems;
  animation-duration: 2s;
  }

  .poem-preview:hover {
    transform: skew(-0.02turn, 4deg);
    box-shadow: 2px 2px #689595;
  }

  .poem-preview-title {
    font-size: 36px;
  }

  .poem-preview-sub {
    font-size: 24px;
  }


  @keyframes abt-appear {
    from {
      color: black
    } to {
      color: #f6c992
  
    }
  }
  
  @keyframes abt-appearLink {
    0%{color:black}
    50%{color:black}
    100%{color:#689595
    }
  }
  
  @keyframes abt-appearDesc {
    0%{color:black}
    50%{color:black}
    100%{color:white
    }
  }
  
  @keyframes abt-appearTP {
    0%{color:black}
    50%{color:black}
    100%{color:#f6c992
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .poem-title {
      font-size: 64px
    }
    .poem-desc-wrapper {
      padding-left: 8%;
      padding-right: 8%;
      font-size: 22px
    }
    .poem-option {
      font-size: 32px;
    }

    .poem-preview-title {
      font-size: 24px;
    }
  
    .poem-preview-sub {
      font-size: 16px;
    }
  }
  
  @media only screen and (max-width: 420px) {
    .poem-desc-wrapper {
      padding-left: 5%;
      padding-right: 5%;
      font-size: 18px
    }
  }
  
  
  @media only screen and (max-width: 350px) {
    .poem-desc-wrapper {
      font-size: 16px
    }
  }
  