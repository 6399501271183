.mast-App {
  background-color:black;
  text-align: center;
  min-height: 100vh
}

.mast-title {
  color:#f6c992;
  font: roundabout;
  font-size: 96px;
  font-weight: 50;
  animation-name: mast-appear;
  animation-duration: 2s
}

.mast-logo-wrapper {
  height: 50vh;
  padding-left: 5%;
  padding-right: 5%
}

.mast-title-wrapper {
  display: flex;
}

.mast-options {
  height: 25vh;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%
}

.mast-option {  
  animation-name: mast-appearLink;  
  font-weight: 50;
  font-size: 32px;
  color: #689595;
}

.mast-option:hover {
  color: #9c6853
}

.mast-option:active {
  color: #fbdfcb
}

.mast-op1 {
  animation-duration: 3s;
}

.entry{
  animation-name: mast-entryAppear;  
  animation-duration: 1.5s;
}


@keyframes mast-appear {
  from {
    color: black
  } to {
    color: #f6c992

  }
}

@keyframes mast-appearLink {
  0%{color:black}
  50%{color:black}
  100%{color:#689595
  }
}

@keyframes mast-entryAppear {
  0% {opacity:0};
  25% {opacity: .6};
  100% {opacity: 1}
}
@media only screen and (max-width: 600px) {
  .mast-title {
    font-size: 64px
  }
  .mast-options {
    height: 15vh
  }
  .mast-logo-wrapper {
    height: 70vh;
  }
  .mast-option {
    font-size: 26px;
  }
}
