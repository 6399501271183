div.ED04{
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  max-width: 800px;
  font-weight: 200;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.flicker-text{
  -webkit-animation: flicker-4 8s linear infinite both;
  animation: flicker-4 8s linear infinite both;
  color:rgb(60, 255, 35);
  font-family: "oeuf"
}

.ED04-0 {    
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  border: 10px rgb(0, 0, 0) solid;
  color: rgb(255, 255, 255);
  height: 100vh;
  font-size: medium;  
  line-height: 1.2;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.ED04-1 {    
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(59, 0, 56);
  border: 10px rgb(0, 0, 0) solid;
  color: rgb(252, 135, 114);
  height: 100vh;
  font-size: x-large;
  line-height: 1.4;
  font-family: "roundabout";

}

.ED04-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(167, 0, 0);
    border: 10px rgb(105, 0, 0) solid;
    color: rgb(255, 238, 86);
    height: 100vh;
    font-size: x-large;
    line-height: 1.4;
    font-family: "roundabout";

}

.ED04-3 {    
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(216, 98, 2);
  border: 10px rgb(204, 26, 26) solid;
  color: black;
  height: 100vh;
  font-size: x-large;
  line-height: 1.4;
  font-family: "roundabout";

}

.ED04-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(230, 174, 20);
  border: 10px rgb(235, 201, 11) solid;
  color: black;
  height: 100vh;
  font-size: x-large;
  line-height: 1.4;
  font-family: "roundabout";

}

.ED04-5 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(21, 105, 0);
  color: rgb(255, 255, 255);
  height: 100vh;
  font-size: x-large;
  line-height: 1.4;
  font-family: "roundabout";

  }

.ED04-6 {    
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(27, 88, 255);
  border: 10px rgb(25, 113, 228) solid;
  height: 100vh;
  font-size: x-large;
  line-height: 1.4;
  font-family: "roundabout";

}


.ED04-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(176, 241, 176);
  color: black;
  font-family: "crates";
  font-weight: 200;
  height: 100vh; 
}


@-webkit-keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}
@keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}