a {
  color: #f6c992;
}

a:hover {
  color:  #689595;
}

.TP {
  animation-name: abt-appearTP;
  animation-duration: 1.5s
}

.abt-desc-wrapper{
color:white;
font-size: 32px;
font-weight: 40;
line-height: 1.3
}

.abt-App {
  background-color:black;
  text-align: center;
  min-height: 100vh
}

.abt-title {
  color:#f6c992;
  font: roundabout;
  font-size: 96px;
  font-weight: 50;
  animation-name: abt-appear;
  animation-duration: 2s
}

.abt-options {
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%
}

.abt-option {  
  animation-name: abt-appearLink;  
  font-weight: 50;
  font-size: 32px;
  color: #689595;
}

.abt-option:hover {
  color: #9c6853
}

.abt-option:active {
  color: #fbdfcb
}

.abt-op1 {
  animation-duration: 3s;
}
.abt-op2 {
  animation-duration: 1s;
}
.abt-op3 {
  animation-duration: 1.5s;
}

.abt-desc-wrapper {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20%;
  padding-right: 20%; 
  
}

.bot-text{
  text-align: justify;
  font-family: "bubble";
  font-size: 36px
}

.abt-desc {
  text-align: justify;
  animation-name: abt-appearDesc; 
}
@keyframes abt-appear {
  from {
    color: black
  } to {
    color: #f6c992

  }
}

@keyframes abt-appearLink {
  0%{color:black}
  50%{color:black}
  100%{color:#689595
  }
}

@keyframes abt-appearDesc {
  0%{color:black}
  50%{color:black}
  100%{color:white
  }
}

@keyframes abt-appearTP {
  0%{color:black}
  50%{color:black}
  100%{color:#f6c992
  }
}

@media only screen and (max-width: 1000px) {
  .abt-title {
    font-size: 64px
  }
  .abt-desc-wrapper {
    padding-left: 8%;
    padding-right: 8%;
    font-size: 22px
  }
  .abt-option {
    font-size: 32px;
  }
}

@media only screen and (max-width: 420px) {
  .abt-desc-wrapper {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 18px
  }
}


@media only screen and (max-width: 350px) {
  .abt-desc-wrapper {
    font-size: 16px
  }
}
