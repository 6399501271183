marquee{
  color: white;
  font-size: 26px
}

.sub-App {
  background-color:black;
  text-align: center;
  min-height: 100vh
}

.sub-title {
  color:#f6c992;
  font: roundabout;
  font-size: 96px;
  font-weight: 50;
  animation-name: mast-appear;
  animation-duration: 2s
}

.sub-desc-wrapper {
  color:white;
font-size: 32px;
font-weight: 40;
line-height: 1.2;
margin-left: 15px;
margin-right: 15px;
  height: 60vh;
  text-align: left;
}

.sub-options {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%
}

.sub-option {  
  animation-name: mast-appearLink;  
  font-weight: 50;
  font-size: 32px;
  color: #689595;
}

.sub-option:hover {
  color: #9c6853
}

.sub-option:active {
  color: #fbdfcb
}

.sub-op1 {
  animation-duration: 3s;
}


@keyframes sub-appear {
  from {
    color: black
  } to {
    color: #f6c992

  }
}

@keyframes sub-appearLink {
  0%{color:black}
  50%{color:black}
  100%{color:#689595
  }
}

@media only screen and (max-width: 600px) {
  .sub-title {
    font-size: 36px
  }
  .sub-option {
    font-size: 26px
  }
  .sub-desc-wrapper {
    font-size: 16px
  }
}
